import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate  } from 'react-router-dom';
import { Container, Typography, Grid, Box, Button, CircularProgress, Paper, Card, CardContent } from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import defaultBanner from '../assets/detailsbg.jpg';
import defaultAvatar from '../assets/defaultAvatar.png';
import OrganizationCard from '../components/OrganizationCard';

const EventDetails = () => {
  const { eventId } = useParams();
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [organizer, setOrganizer] = useState(null);
  const [ageRestriction, setAgeRestriction] = useState(null);
  const navigate = useNavigate();

  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    const formattedDate = new Date(dateString).toLocaleDateString('ru-RU', options);
    return formattedDate;
  };

  const formatTime = (dateString) => {
    const options = { hour: '2-digit', minute: '2-digit' };
    const formattedTime = new Date(dateString).toLocaleTimeString([], options);
    return formattedTime;
  };

  const handleBuyTickets = () => {
    navigate(`/buy-tickets/${eventId}`);
  };

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/events/${eventId}`);
        const data = await response.json();
        setEvent(data);
  
        // Fetch organizer details
        const organizerResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/organization/getByEventId/${eventId}`);
        const organizerData = await organizerResponse.json();
        setOrganizer(organizerData);
  
        console.log(data.ageRestrictionId);
        // Fetch age restriction details if ageRestrictionId exists
        if (data.ageRestrictionId) {
          const ageRestrictionResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/age-restriction/${data.ageRestrictionId}`);
          const ageRestrictionData = await ageRestrictionResponse.json();
          setAgeRestriction(ageRestrictionData);
        }
  
      } catch (error) {
        console.error('Error fetching event details:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchEventDetails();
  }, [eventId]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" padding="20px">
        <CircularProgress />
      </Box>
    );
  }

  if (!event) {
    return (
      <Container>
        <Typography variant="h5">Event not found</Typography>
      </Container>
    );
  }

  const bannerImage = event.bannerImage
    ? `${process.env.REACT_APP_API_URL}/api/image?path=${encodeURIComponent(event.bannerImage)}`
    : defaultBanner;

  const eventDate = new Date(event.date.$date).toLocaleDateString();
  const eventTime = new Date(event.date.$date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

  // Проверяем, что данные об организаторе загружены
  const organizerAvatar = organizer?.avatar
    ? `${process.env.REACT_APP_API_URL}/api/image?path=${encodeURIComponent(organizer.avatar)}`
    : defaultAvatar;

  const organizerBackgroundImage = organizer?.backgroundImage
    ? `${process.env.REACT_APP_API_URL}/api/image?path=${encodeURIComponent(organizer.backgroundImage)}`
    : defaultBanner;

  return (
    <div>
      <Box
        sx={{
          width: '100%',
          height: '300px',
          backgroundImage: `url(${bannerImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#fff',
        }}
      >
        <Typography variant="h3" component="h1" style={{ fontWeight: 'bold', textShadow: '2px 2px 5px rgba(0, 0, 0, 0.5)' }}>
          {event.name}
        </Typography>
      </Box>
      <Container sx={{ marginTop: '40px' }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            <Paper sx={{ padding: '20px' }} elevation={0}>
              <img
                src={`${process.env.REACT_APP_API_URL}/api/image?path=${encodeURIComponent(event.imageUrl)}`}
                alt={event.name}
                style={{ width: '100%', borderRadius: '8px', marginBottom: '20px' }}
              />
              <Typography variant="h5" component="h2" gutterBottom>
                {event.name}
              </Typography>
              <Typography variant="body1" paragraph>
                {event.description}
              </Typography>
            </Paper>
            <Paper sx={{ padding: '20px' }} elevation={0}>
              <Typography variant="h6" gutterBottom>
                Event Information
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
                <EventIcon sx={{ marginRight: '8px' }} />
                <Typography variant="body2">
                  {formatDate(event.date)}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
                <AccessTimeIcon sx={{ marginRight: '8px' }} />
                <Typography variant="body2">
                  {formatTime(event.date)}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <LocationOnIcon sx={{ marginRight: '8px' }} />
                <Typography variant="body2">
                  {event.address}, {event.city}, {event.country}, {event.zipcode}
                </Typography>
              </Box>
            </Paper>
          </Grid>
          
          <Grid item xs={12} md={4}>
            {/* Карточка организатора */}
            {organizer && (
              <Paper sx={{ marginBottom: '20px' }}>
                <Link to={`/organization/${organizer.organization.organizationSlug}`} style={{ textDecoration: 'none' }}>
                  <Card>
                    <div
                      style={{
                        position: 'relative',
                        height: '140px',
                        backgroundImage: `url(${organizerBackgroundImage})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                      }}
                    >
                      <img
                        src={organizerAvatar}
                        alt="Organization Avatar"
                        style={{
                          position: 'absolute',
                          bottom: '-30px',
                          left: '50%',
                          transform: 'translateX(-50%)',
                          width: '60px',
                          height: '60px',
                          borderRadius: '50%',
                          border: '3px solid white',
                        }}
                      />
                    </div>
                    <CardContent style={{ textAlign: 'center', marginTop: '40px' }}>
                      <Typography variant="h6" component="div">
                        {organizer.organization.organizationName}
                      </Typography>
                      <Typography 
                        variant="body2" 
                        color="text.secondary" 
                        sx={{
                          display: '-webkit-box',
                          WebkitBoxOrient: 'vertical',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          WebkitLineClamp: 3, // Ограничиваем до 3 строк
                        }}
                      >
                        {organizer.organization.description}
                      </Typography>
                    </CardContent>
                  </Card>
                </Link>
              </Paper>
            )}
            <Paper sx={{ padding: '20px', marginBottom: '20px' }}>
              {event.isActive ? (
                <a
                  href={`/api/ticket/buy-ticket?eventId=${event._id}`}
                  style={{
                    textDecoration: 'none',
                    display: 'block',
                    backgroundColor: '#e28743',
                    color: 'white',
                    padding: '10px 20px',
                    textAlign: 'center',
                    borderRadius: '4px',
                    marginTop: '20px',
                  }}
                >
                  {event.price} € - Get Tickets Now
                </a>
              ) : (
                <Box
                  sx={{
                    display: 'block',
                    backgroundColor: 'rgba(226, 135, 67, 0.1)',
                    color: '#e28743',
                    padding: '10px 20px',
                    textAlign: 'center',
                    borderRadius: '4px',
                    marginTop: '20px',
                    border: '1px solid #e28743',
                  }}
                >
                  Sold Out
                  <Typography
                    variant="body2"
                    sx={{
                      marginTop: '10px',
                      color: '#333',
                      textAlign: 'center',
                      fontSize: '14px',
                    }}
                  >
                    Все онлайн билеты на это мероприятие распроданы. Вы можете приобрести билеты на входе в кассе при наличии свободных мест.
                  </Typography>
                </Box>
              )}
            </Paper>
            {/* Карточка возрастного ограничения */}
            {ageRestriction && (
              <Paper sx={{ padding: '20px', marginBottom: '20px' }}>
                <Box sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <img
                    src={`${process.env.REACT_APP_API_URL}/api/image?path=${encodeURIComponent(ageRestriction.imageUrl)}`}
                    alt="Age Restriction"
                    style={{ width: '80px', height: '80px', marginBottom: '10px' }}
                  />
                  <Typography variant="body2" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                    Важно: это событие имеет возрастное ограничение ({ageRestriction.name}).
                  </Typography>
                </Box>
              </Paper>
            )}

            {/* <Paper sx={{ padding: '20px' }} elevation={0}>
              <Typography variant="h6" gutterBottom>
                Location
              </Typography>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3648.9134942872954!2d90.4216823144715!3d23.798249092603196!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c77c7c1f0d41%3A0xa6a8c3c97e8d9714!2sGulshan%20Square!5e0!3m2!1sen!2sbd!4v1603895407125!5m2!1sen!2sbd"
                width="100%"
                height="200"
                frameBorder="0"
                style={{ border: 0 }}
                allowFullScreen=""
                aria-hidden="false"
                tabIndex="0"
              ></iframe>
            </Paper> */}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default EventDetails;
